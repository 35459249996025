<script setup lang="ts">
import { Media } from '@/types/mediaTypes'
import { computed, ref, watch } from 'vue'
import { useMediaStore } from '@/stores/useMedia'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseButton from '@/components/library/buttons/BaseButton.vue'
import DownloadRenditionRow from '@/components/library/downloads/DownloadRenditionRow.vue'
import { POSITION, useToast } from 'vue-toastification'
import { useI18n } from 'vue-i18n'
import BaseDialog from '@/components/library/dialog/BaseDialog.vue'

const props = defineProps<{
  showDialog: boolean
  assetId: Media['id']
}>()

const { t } = useI18n()

const emits = defineEmits<{
  (e: 'close:dialog'): void
}>()

const isLoading = ref(false)
const mediaStore = useMediaStore()

const toast = useToast()

const assetDetails = computed(() => {
  return mediaStore.state.details
})

const availableRenditions = computed(() => {
  if (!assetDetails.value) {
    return []
  }
  return assetDetails.value.renditions
    .filter((rendition) => {
      return (
        rendition.status === 'ready' &&
        (rendition.type === 'original' || rendition.type === 'derived') &&
        !rendition.deprecated
      )
    })
    .sort((a, b) => {
      if (a.type === 'original' && b.type !== 'original') return -1
      if (b.type === 'original' && a.type !== 'original') return 1

      if (a.mimeType !== b.mimeType) {
        return a.mimeType.localeCompare(b.mimeType)
      }

      const resA = (a.width ?? 0) * (a.height ?? 0)
      const resB = (b.width ?? 0) * (b.height ?? 0)
      if (resA !== resB) {
        return resA - resB
      }

      return a.filesize - b.filesize
    })
})

async function getAssetDetail() {
  try {
    if (assetDetails.value?.id !== props.assetId) {
      isLoading.value = true
      await mediaStore.fetchDetails(props.assetId)
    }
  } catch (e) {
    if (!e.message?.message) {
      toast.error(`${t('media.failedToFetch')} (${t('errors.unexpectedError')})`, {
        position: POSITION.BOTTOM_RIGHT
      })
      emits('close:dialog')
    } else {
      toast.error(`${t('media.failedToFetch')}(${e.message.message}`, {
        position: POSITION.BOTTOM_RIGHT
      })
      emits('close:dialog')
    }
  } finally {
    isLoading.value = false
  }
}

watch(
  () => props.showDialog,
  async (isOpen) => {
    if (isOpen) {
      await getAssetDetail()
    }
  }
)
</script>

<template>
  <base-dialog
    :title="`${$t('download.title')} ${assetDetails?.name ?? ''}`"
    :show-dialog="showDialog"
    @close:dialog="$emit('close:dialog')"
  >
    <div>
      <div class="min-h-[200px]">
        <!--   body       -->
        <div
          v-if="isLoading"
          class="min-h-[200px] w-full flex items-center justify-center bg-neutral-200 bg-opacity-35"
        >
          <font-awesome-icon
            icon="fa-regular fa-spinner"
            class="text-lg text-neutral-600 animate-spin"
          />
        </div>
        <div
          v-if="assetDetails?.renditions"
          class="w-full min-h-[200px] max-h-[80vh] overflow-y-auto"
        >
          <table class="w-full whitespace-nowrap">
            <thead class="border-b border-b-neutral-500">
              <tr>
                <th class="text-left">{{ $t('download.mimeType') }}</th>
                <th class="text-left">{{ $t('download.resolution') }}</th>
                <th class="text-left">{{ $t('download.fileSize') }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody class="table-body">
              <download-rendition-row
                v-for="rendition in availableRenditions"
                :key="rendition.id"
                :asset-id="assetId"
                :rendition="rendition"
              />
            </tbody>
          </table>
        </div>
      </div>
      <div class="flex justify-end gap-4 mt-4">
        <base-button
          class="border-primary border-2 p-2 rounded-md text-primary hover:bg-primary hover:text-white active:bg-primary active:text-white transition-all duration-500"
          :icon="{ name: 'fa-xmark', type: 'far' }"
          :disabled="isLoading"
          @click="$emit('close:dialog')"
        >
          {{ $t('download.close') }}
        </base-button>
      </div>
    </div>
  </base-dialog>
</template>

<style scoped lang="postcss">
.table-body::before {
  height: 0.5em;
  display: table-row;
  content: '';
}
</style>
