import {
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faPlay,
  faImage,
  faMusic,
  faWaveformLines,
  faVolume,
  faVolumeSlash,
  faXmark,
  faBarsFilter,
  faRectangleHistory,
  faFile,
  faFilePdf,
  faSpinner,
  faCheck,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faMagnifyingGlass,
  faStar,
  faList,
  faGrid,
  faXmark as faXmarkSolid,
  faCircle,
  faChevronLeft as faChevronLeftSolid,
  faBarsFilter as faBarsFilterSolid,
  faFileMagnifyingGlass as faFileMagnifyingGlassSolid,
  faMessageText as faMessageTextSolid,
  faListTree as faListTreeSolid,
  faRectangleHistory as faRectangleHistorySolid,
  faShareNodes,
  faDownload as faDownloadSolid,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faPhotoFilm,
  faIcons,
  faFileMagnifyingGlass as faFileMagnifyingGlassLight,
  faMessageText as faMessageTextLight,
  faListTree as faListTreeLight,
  faRectangleHistory as faRectangleHistoryLight,
  faLink as faLinkLight
} from "@fortawesome/pro-light-svg-icons";

import {
  faArrowDown,
  faEnvelope,
  faFilter,
  faLink,
  faFilm
} from "@fortawesome/pro-solid-svg-icons";
// import { faFacebook, faLinkedin, faTwitter, faXing } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";


export default function useFontawesome() {
  return library.add(
    faFile,
    faFilePdf,
    faFilm,
    faMagnifyingGlass,
    faFilter,
    faGrid,
    faList,
    faChevronDown,
    faChevronUp,
    faChevronLeft,
    faChevronLeftSolid,
    faChevronRight,
    faEnvelope,
    faCircle,
    faArrowDown,
    // faFacebook,
    // faTwitter,
    // faLinkedin,
    // faXing,
    faLink,
    faStar,
    faPhotoFilm,
    faPlay,
    faImage,
    faMusic,
    faIcons,
    faWaveformLines,
    faVolume,
    faVolumeSlash,
    faXmark,
    faXmarkSolid,
    faBarsFilter,
    faBarsFilterSolid,
    faRectangleHistory,
    faFileMagnifyingGlassLight,
    faMessageTextLight,
    faListTreeLight,
    faRectangleHistoryLight,
    faLinkLight,
    faFileMagnifyingGlassSolid,
    faMessageTextSolid,
    faListTreeSolid,
    faRectangleHistorySolid,
    faShareNodes,
    faDownloadSolid,
    faSpinner,
    faCheck
  );
}
