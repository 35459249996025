<script setup lang='ts'>
import BaseButton from '@/components/library/buttons/BaseButton.vue';
import type { Media, Rendition } from '../../../types/mediaTypes';
import { computed, ref } from 'vue';
import {readableFileSize} from '@/utils/functions/readableFileSize';
import downloadService from '@/services/downloadService';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { POSITION, useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';

type DownloadRenditionRowProps =  {
  rendition: Rendition;
  assetId: Media['id'];
}

const toast = useToast();
const { t } = useI18n();

const props = defineProps<DownloadRenditionRowProps>();
const isPreparing = ref(false);
const downloadUrl = ref<string | undefined>();

const fileSize = computed(() => {
  return readableFileSize(props.rendition.filesize);
});

async function createDownload() {
  try {
    isPreparing.value = true;
    const generateDownloadRequest = await downloadService.createDownload({
      title: `${t('download.title')} ${props.rendition.id}`,
      selectedRenditionIds: [props.rendition.id],
      compressed: false
    });

    const response = await generateDownloadRequest.json();
    if(!generateDownloadRequest.ok) {
      throw new Error(response.message);
    }
    const responseDownloadUrl = response.data?.files[0].url;
    const downloadLink = document.createElement('a');
    downloadLink.href = responseDownloadUrl;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    downloadUrl.value = responseDownloadUrl;

  }catch (e) {
    console.error(e);
    if (!e.message) {
      toast.error(`${t('download.downloadFailed')} (${t('errors.unexpectedError')})`, { position: POSITION.BOTTOM_RIGHT })
    }else{
      toast.error(`${t('download.downloadFailed')} (${e.message})`, { position: POSITION.BOTTOM_RIGHT })
    }
  }finally {
    isPreparing.value = false;
  }
}

</script>

<template>
  <tr class="rendition-row" >
    <td class="text-left">
      <span class="whitespace-nowrap">
        {{rendition.mimeType}}
      </span>
    </td>
    <td class="text-left whitespace-nowrap">
      <span v-show='rendition.width && rendition.height'>
          {{rendition.width}}x{{rendition.height}}
      </span>
    </td>
    <td class="text-left whitespace-nowrap">
      {{fileSize}}
    </td>
    <td class="text-center">
       <span v-if="rendition.type === 'original'" class="border border-primary text-primary px-1.5 rounded-md w-full ">
          {{$t("download.originalFile")}}
        </span>
    </td>
    <td class="text-right">
      <base-button
        class="w-[200px] bg-primary border-primary whitespace-nowrap border-2 px-1.5 py-0.5 rounded-md text-white hover:opacity-60 transition-all duration-500"
        :icon="!isPreparing ? {name: 'download', type: 'fas'} : undefined"
        :disabled="isPreparing || downloadUrl"
        @click="createDownload"
      >
        <font-awesome-icon v-if='isPreparing' :icon="['far', 'spinner']" class='text-lg text-white animate-spin' />
        {{
          isPreparing ? $t('download.preparing') : downloadUrl ? $t('download.downloadStarted') : $t('download.title')
        }}
      </base-button>
    </td>
  </tr>
</template>

<style scoped lang='postcss'>
  .rendition-row {
    @apply border-b-4 border-b-transparent first:mt-4 ;
  }
</style>