<template>
  <transition-group tag="ul" name="fade" :class="['list', { 'list--grid': data?.length }]">
    <portal-card
      v-for="d in data"
      v-if="data?.length"
      :key="d.id"
      v-bind="d"
      @share="share($event, d.file)"
      @open-download="handleOpenDownload($event)"
    />
    <base-observer
      v-if="data?.length && !isFetchingMore"
      key="observer-card"
      :intersection-options="{ root: null, rootMargin: '0px', threshold: 0.5 }"
      @callback="handleInfiniteScroll"
    />
    <card-skeleton v-if="isFetchingMore" v-for="index in 8" :key="'card-skeleton' + index" />
    <p
      class="text-xl px-8 font-bold py-12"
      v-if="!isFetchingMore && (!data?.length || searchResults.result?.length)"
    >
      {{ $t('feedback.emptyData') }}
    </p>
    <share-dialog
      v-if="shareDialogIsOpen"
      :title="$t('share')"
      :id="selectedAssetId"
      :duration="duration"
      :embedViaJsText="embedViaJsText"
      :aspectRatio="aspectRatio"
      @close:dialog="shareDialogIsOpen = false"
    />
    <download-dialog :show-dialog='openDownloadDialog' :asset-id='selectedAssetId' @close:dialog='handleCloseDownload'/>
  </transition-group>
</template>

<script setup lang="ts">
import PortalCard from '@/components/library/portal/views/gridViews/PortalCard.vue'
import BaseObserver from '@/components/library/util/BaseObserver.vue'
import { useInfiniteScroll } from '@/composables/useInfiniteScroll'
import { inject, computed, ref, reactive } from 'vue'
import { storeToRefs } from 'pinia'
import CardSkeleton from '@/components/library/skeleton/views/CardSkeleton.vue'
import { useSearchStore } from '@/stores/useSearch'
import ShareDialog from '@/components/library/ShareDialog.vue'
import { useMediaStore } from '@/stores/useMedia'
import DownloadDialog from '@/components/library/DownloadDialog.vue'
import { Media } from '@/types/mediaTypes';

const searchStore = useSearchStore()
const searchResults = storeToRefs(searchStore)

const { state, fetchDetails } = useMediaStore()

const selectedAssetId = ref<Media['id']>()
const duration = ref('')
const aspectRatio = ref('')
const embedViaJsText = ref('')
const shareDialogIsOpen = ref(false)
const openDownloadDialog = ref(false)

const allData = inject('data', [])
const mode = inject('mode', 'channels')
const data = computed(() => {
  if (searchResults.result.value === 'empty') {
    return []
  }
  // search data
  if (searchResults.result.value?.channels) {
    return searchResults.result.value.channels
  } else if (searchResults.result.value?.singleChannel) {
    return searchResults.result.value.singleChannel
  } else if (searchResults.result.value?.all) {
    return searchResults.result.value.singleChannel
  } else {
    // standart data
    return allData.value
  }
})

const { handleInfiniteScroll, isFetchingMore } = useInfiniteScroll()

function handleOpenDownload(id: Media['id']) {
  selectedAssetId.value = id;
  openDownloadDialog.value = true;
}

function handleCloseDownload() {
  selectedAssetId.value = undefined;
  openDownloadDialog.value = false;
  state.details = null;
}

function share(event, file) {
  getEmbedJsText(event)
  duration.value = file.duration ? file.duration : ''
  selectedAssetId.value = event
  aspectRatio.value = file.aspectRatio
  shareDialogIsOpen.value = true
}

function getEmbedJsText(id) {
  fetchDetails(id).then(() => {
    embedViaJsText.value = state.details.player
  })
}
</script>

<style lang="postcss" scoped>
.list {
  @apply w-full h-full grow;

  &--grid {
    @apply grid content-start grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-7  grid-flow-row gap-x-4 gap-y-5 p-2 items-start transition-all;
  }
}
</style>