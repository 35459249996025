<template>
  <base-card
    :class="['portal-card', {'portal-card--channels': mode === 'channels', 'portal-card--rounded-b-none': tabs && tabs.length > 0, 'featured': featuredAtUtc}]"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave" tag="li">
    <div v-if="featuredAtUtc && mode === 'channels'" class="featured-wrapper">
      <font-awesome-icon icon="fa-star" :aria-label="$t('altText.featuredBtn')" />
    </div>
    <base-card-layers v-if="mode === 'channels'" :hide-layers="isCardHovered" />
    <component class="portal-card__header" :is="mediaViewComponent" :route="mediaRoute" :thumbnail="thumbnail"
               :media-type="type" type="thumbnail"
               :source="type === 'document' && placeholder"
               :rendition="{source: preview?.url, mimeType:'application/x-mpegurl'}"
               :preview="preview"
               :alt="$t('altText.thumbnail', {type: type, name: name, description: description})"
               :id="id" />
    <div class="portal-card__body">
      <router-link :to="mediaRoute">
        <base-title tag="h6" class='!text-base' v-if="!nameHighlights || !nameHighlights.length">{{ name }}</base-title>
        <base-title tag="h6" class='!text-base' v-else v-for="name in nameHighlights" v-html="name" />
      </router-link>
      <div class="views-container">
        <base-asset-type v-if="type && type !== 'group'" class='mr-2' :type="t(`types.${type}`)" :file='file'/>
        <base-views :label="label" :count="counter" />
        <base-date :date="audit?.createdAtUtc" />
      </div>
      <base-scroller class="mt-2" v-if="tags && tags.length" :is-parent-hovered="isCardHovered">
        <base-tag size='small' :class="{'!cursor-pointer': $route.name !== 'ChannelsView'}"
                  v-for="(tag, index) in tags" @click="handleTagClick(tag)" :key="'t' + index">{{ tag }}
        </base-tag>
      </base-scroller>
      <div class="flex justify-end gap-2" v-if="mode !== 'channels' && (channelState.details.publicPortalSharingEnabled || channelState.details.publicPortalDownloadEnabled)">
        <base-button v-if='channelState.details.publicPortalDownloadEnabled' class="share-button" @click="$emit('openDownload', id)" :icon="{name: 'download', type: 'fa'}"></base-button>
        <base-button v-if='channelState.details.publicPortalSharingEnabled' class="share-button" @click="$emit('share', id)" :icon="{name: 'share-nodes', type: 'fa'}"></base-button>
      </div>
    </div>
    <div class="portal-card__actions" v-if="tabs && tabs.length > 0">
      <search-tab-bar class="tab-container" :tabs="tabs" :id="id"/>
    </div>
  </base-card>
</template>

<script setup lang="ts">
// *** IMPORT COMPONENTS ** //
import BaseImage from "@/components/library/ui/BaseImage.vue";
import BaseAssetType from '@/components/library/ui/BaseAssetType.vue';
import BasePreview from "@/components/library/player/BasePreview.vue";
import BaseViews from "@/components/library/counter/BaseViews.vue";
import BaseDate from "@/components/library/counter/BaseDate.vue";
import BaseTitle from "@/components/library/typography/BaseTitle.vue";
import BaseScroller from "@/components/library/scrollContainers/BaseScroller.vue";
import BaseTag from "@/components/library/ui/BaseTag.vue";
import BaseCardLayers from "@/components/library/ui/BaseCardLayers.vue";
import placeholder from "@/assets/placeholder.png";
// *** IMPORT FUNCTIONS ** //
import { paths } from "@/settings/settings";
import { computed, inject, provide, ref, watchEffect } from 'vue';
// *** IMPORT COMPOSABLES ** //
import { useRoute } from "vue-router";
import SearchTabBar from "@/components/library/search/SearchTabBar.vue";
import { useSharedPortal } from "@/composables/useSharedPortal";
import { useI18n } from 'vue-i18n';
import { useChannelStore } from "@/stores/useChannel";
// *** IMPORT TYPES ** //
// import type { Media }  from "@/types/mediaTypes";
// For some reason import as interface of type does not work VITE 💥
interface PublicChannels {
  audit: Audits,
  description?: string,
  featuredAtUtc?: string | null,
  id: string;
  thumbnail: Thumbnail;
  manualSort?: number;
  mediaCount?: number;
  name: string;
  type: "group";
  visibility?: "public";
}

interface Thumbnail {
  height: number,
  url: string,
  width: number
}

interface Child {
  type: string,
  value: string | undefined,
  component: string | undefined
}

interface Tab {
  title: string,
  children: Child[]
}

interface ChannelSearch {
  descriptionHighlight?: string[],
  nameHighlights?: string[],
  tabs?: any[]
}

interface Media extends PublicChannels, ChannelSearch {
  channels?: [],
  feedback?: null,
  file?: File,
  id: string,
  ingestInformation?: ingestInformation,
  permissions?: [],
  preview?: Preview,
  status?: string
  tags?: string[],
  usage?: Usage,
}

type Audits = {
  createdAtUtc: string, createdBy: object, updatedAtUtc: string
}

interface Thumbnail {
  height: number,
  url: string,
  width: number
}

interface File {
  duration: string,
  durationInSeconds: string,
  mimeType: string,
  name: string,
  size: string,
}

interface ingestInformation {
  ingestJobStatus: "finished" | "error",
  progress: number
}

interface Preview {
  url: string,
}

interface Usage {
  downloads: number,
  views: number
}

defineEmits<{
  (e: 'share' | 'openDownload', id: Media['id']): void
}>()

// type Combinable = PublicChannels | Media
const props = defineProps<Media>();

const { state: channelState } = useChannelStore();

const route = useRoute();

const mode = inject("mode", "channels");

const {t} = useI18n();
const delayTimeoutForComponentChange = ref(0);
const isCardHovered = ref(false);
const shouldChangeComponent = ref(false);

const { handleTagClick } = useSharedPortal();

watchEffect(async () => {
  clearTimeout(delayTimeoutForComponentChange.value);

  if (isCardHovered.value) {
    shouldChangeComponent.value = await new Promise(resolve => {
      delayTimeoutForComponentChange.value = setTimeout(() => {
        resolve(true);
      }, 1000);
    });
  } else {
    shouldChangeComponent.value = false;
  }
});

const mediaViewComponent = computed(() => shouldChangeComponent.value && (props.type === "video" || props.type === "audio") ? BasePreview : BaseImage);


const label = computed(() => {
  if (mode === "channel") {
    return "views";
  } else {
    return t('assets');
  }
});

const mediaRoute = computed(() => {
  // On channel detail page we can take advantage of route
  if (route.params.channelId) {
    return paths.CHANNELS_ROUTE + route.params.channelId + paths.MEDIA_ROUTE + props.id;
  } else {
    // On channels page we get the channel id as a prop
    return paths.CHANNELS_ROUTE + props.id;
  }
});

const counter = computed(() => {
  if (props.mediaCount) {
    return props.mediaCount;
  }
  if (props.usage?.views) {
    return props.usage.views;
  }
});


function handleMouseEnter() {
  isCardHovered.value = true;
}

function handleMouseLeave() {
  isCardHovered.value = false;
}
provide('mediaRoute', mediaRoute.value)
</script>


<style lang="postcss" scoped>
.portal-card {
  @apply relative scale-100;
  /* scale-100 because otherwise layers are not shown */

  &:hover &__header {
    @apply lg:rounded-b-none delay-300 shadow-none;
  }

  &--rounded-b-none {
    @apply rounded-b-none;
  }
;

  &__body {
    @apply p-2 flex flex-col gap-1;
    /* Spaces the item in card body */

    :deep(.title) {
      @apply text-xl;
    }
  }

  &__actions {
    @apply absolute bg-light-100 bottom-0 w-full shadow-lg rounded-b-lg translate-y-[99%] overflow-hidden z-10 opacity-0 delay-300 transition-opacity;

    .tab-container {
      @apply px-2
    }
  }

  &:hover {
    @apply z-20
  }

  &:hover &__actions {
    @apply opacity-100;
  }
}

.views-container {
  @apply flex items-center;
  p, span  {
    @apply !text-sm;
  }
  :last-child {
    @apply ml-auto;
  }
}

.share-button {
  @apply text-dark-500 text-xl opacity-0 delay-300 transition-opacity invisible;
}

.portal-card:hover {
   .share-button {
    @apply opacity-100 delay-300 transition-opacity visible;
  }
}

.featured-wrapper {
  @apply absolute flex items-center justify-center text-center left-2 top-2 text-[1.5rem] z-[10001] text-white cursor-pointer bg-neutral-800 bg-opacity-50 rounded-full w-10 h-10;
}

</style>