import { useFetch } from "@/utils/useService";
import { Media, Rendition } from '../types/mediaTypes';

type DownloadBody = {
  title: string;
  selectedMediaIds: Media['id'][];
  selectedRenditionIds: Rendition['id'];
  compressed: false
}

export default {
  createDownload( body: DownloadBody ) {
      return useFetch(`/downloads`, { method: "POST", body });
  },
};