
export function readableFileSize(bytes: number){
  if(isNaN(bytes)) {
    throw new Error('Bytes is not a number!')
  }
  if (bytes === 0) return "0 Bytes";
  const decimals = 2;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${parseFloat((bytes/ Math.pow(1024, i)).toFixed(decimals))} ${sizes[i]}`;
}