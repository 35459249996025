import { useConfigStore } from "@/stores/useConfig";

export async function useFetch(url = "", { method, body } = { method: "GET" }) {
  try {
    const { apiConfig, fetchToken } = useConfigStore();

    if (apiConfig.token === null && !apiConfig.isFetchingToken) {
      await fetchToken();
    } else {
      await (function() {
        return new Promise((resolve) => {
          const wait = () => {
            if (apiConfig.isFetchingToken) {
              setTimeout(wait, 100);
            } else {
              resolve(true);
            }
          };
          wait();
        });
      })();
    }
    return fetch(`${apiConfig.baseUrl}${url}`, {
      method: method,
      body: JSON.stringify(body),
      headers: {
        Authorization: apiConfig.token,
        "Content-Type": "application/json"
      }
    });
  } catch (e) {
    throw e;
  }
}

export async function useFetchFullURL(fullURL = "", { method } = { method: "GET" }) {
  const { apiConfig } = useConfigStore();
  return await fetch(fullURL, {
    method: method,
    headers: {
      Authorization: apiConfig.token,
      "Content-Type": "application/json"
    }
  });
}

export async function useAuth() {
  // try {
  return await fetch(
    "https://alcatraz.streamdiver.ai/realms/dev-staging/protocol/openid-connect/token",
    {
      method: "POST",
      body: "grant_type=password&username=admin&password=EX7vncbGCivs9L4HTbvo",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Basic bllVTWIycmQzWmNLdzVYQ2p1dUo6"
      }
    }
  )
    .then(function(resp) {
      // Return the response as JSON
      if (!resp.ok) {
        throw new Error("Did not work");
      }
      return resp.json();
    })
    .then(function(data) {
      // Log the API data
      //console.log('token', data);
      // curSessionToken = data.access_token;
      // console.log(data.access_token);
    })
    .catch((err) => {
      // Log any errors
      console.error("something went wrong", err);
    });
}
